<template>
  <div>
    <portal to="pageHeaderRight">
      <contact-dropdown />
    </portal>

    <div v-for="(page, i) in pages" :key="i" class="brand-category">
      <div class="grid-x grid-margin-x">
        <div class="cell auto">
          <h2 class="light">{{ page.name }}</h2>
        </div>
      </div>

      <div class="spacer--30"></div>

      <div class="grid-x large-12 card-list card-list--small-margin">
        <div v-for="(child, i) in page.children" :key="i" class="cell small-4 grid-padding-x last-child">
          <router-link
            :to="{ name: brandDetailRoute, params: { pathName: child.slug } }"
            class="button--card has-icon"
            :class="child.slug ? '' : 'inactive'"
            v-if="child.exists"
          >
            <i :class="child.icon"></i>
            {{ child.name }}
          </router-link>
          <span
            v-else-if="$store.state.is_brand_manager"
            class="cell button--card has-icon inactive"
            @click="createPage(brandEditRoute, child)"
          >
            <i :class="child.icon"></i>
            {{ child.name }}
          </span>

          <span v-else class="cell button--card has-icon inactive">
            <i :class="child.icon"></i>
            {{ child.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewPageModal from '@/views/brand/components/NewPageModal'

export default {
  name: 'BrandList',
  props: {
    pages: Array
  },
  methods: {
    createPage (routeName, page) {
      this.$modal.show(
        NewPageModal,
        {
          routeName: routeName,
          page: page
        },
        {
          draggable: false,
          scrollable: false,
          classes: 'text-center',
          height: 'auto',
          width: '900px'
        }
      )
    }
  },
  computed: {
    brandDetailRoute: function () {
      if (this.$route.name === 'BrandGuidelineList') {
        return 'BrandGuidelineDetail'
      }
      return 'BrandDetail'
    },
    brandEditRoute: function () {
      if (this.$route.name === 'BrandGuidelineList') {
        return 'BrandGuidelineDetailEdit'
      }
      return 'BrandDetailEdit'
    }
  }
}
</script>

<style scoped lang="scss">
.brand-introdution {
  background: var(--button-primary-color);
  color: var(--button-primary-text-color);
  &__step{
    margin-top: calc(30px);
    background: $light-gray-bg;
    padding: calc(20px);
    h4 {
      color: var(--button-primary-color);
      margin-bottom: calc(5px);
    }
  }
}
.brand-category {
  background: $white;
  border-radius: $global-radius;
  padding: calc(30px);
  margin-bottom: calc(30px);
}

.card-list {
  > .cell {
    width: 100%;

    > a {
      width: 100%;
    }
  }
  .inactive {
    cursor: pointer;
    &:hover {
      border-color: #666666;
    }
  }
}

.button--card {
  margin-bottom: 0;
  border: solid 2px var(--primary-color);
  background: $white;
  position: relative;

  span {
    cursor: pointer;
    margin-right: 20px;
  }
}
.grid-padding-x {
  padding-right:10px;
}
.row > .last-child {
  padding-right: 0px;
}

</style>
